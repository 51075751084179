<template>
  <b-modal
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      v-model="visibleModal"
      title="Należy ustawić Imię i Nazwisko"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <label>Imię:</label>
      <input v-model="form.firstName" class="form-control" :class="{ 'is-invalid': $v.form.firstName.$error }" />
      <div v-if="!$v.form.firstName.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="form-group text-center">
      <label>Nazwisko:</label>
      <input v-model="form.lastName" class="form-control" :class="{ 'is-invalid': $v.form.lastName.$error }" />
      <div v-if="!$v.form.lastName.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="text-right">
      <b-button @click="set" variant="success">Ustaw</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {userService} from "@/helpers/user.service";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";

export default {
  name: "needs-to-set-full-name-modal",

  data() {
    return {
      visibleModal: false,

      form: {
        firstName: "",
        lastName: ""
      }
    }
  },

  validations: {
    form: {
      firstName: { required },
      lastName: { required }
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.firstName = ""
      this.form.lastName = ""
    },

    set() {
      this.submitted = true

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.form)
      axios.post(`/user/change-full-name`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Poprawnie zaktualizowano dane!", "success").then(() => {
          this.hideModal()
          userService.login(true)
        })
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })

      this.submitted = false
    }
  }

}
</script>
export const state = () => ({
    rolePermissions: []
})

export const getters = {

    hasPermission: state => (permissionType) => {
        return state.rolePermissions.some(rolePermission => rolePermission.permissionType === permissionType)
    },

}

export const mutations = {

    SET_ROLE_PERMISSIONS(state, rolePermissions) {
        state.rolePermissions = rolePermissions;
    }

}

export const actions = {

    setRolePermissions({commit}, payload) {
        commit('SET_ROLE_PERMISSIONS', payload)
    }

}

// const getDefaultState = () => {
//     return {
//         items: [],
//         status: 'empty'
//     }
// }
//
// // initial state
// const state = getDefaultState()
//
// const actions = {
//     resetCartState ({ commit }) {
//         commit('resetState')
//     },
//     addItem ({ state, commit }, item) { /* ... */ }
// }
//
// const mutations = {
//     resetState (state) {
//         // Merge rather than replace so we don't lose observers
//         // https://github.com/vuejs/vuex/issues/1118
//         Object.assign(state, getDefaultState())
//     }
// }
//
// export default {
//     state,
//     getters: {},
//     actions,
//     mutations
// }
<template>
  <b-form-select v-model="internalValue" size="sm" :options="options" @input="updateValue" />
</template>

<script>
export default {
  name: "ecat-formselect",
  props: {
    id: {
      type: String,
      required: true
    },

    value: {
      type: Number
    },

    options: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      internalValue: this.value // Store the prop value in a local data property
    };
  },

  methods: {
    updateValue(value) {
      this.internalValue = value; // Update the local value
      this.$emit('input', value); // Emit the updated value to the parent
      this.$store.dispatch("formselect/setFormSelect", { id: this.id, value }); // Optionally, dispatch to Vuex
    },

    async loadValue() {
      const ecatFormSelect = await this.$store.getters["formselect/getFormSelect"](this.id);
      if (ecatFormSelect) {
        this.internalValue = ecatFormSelect.value; // Update the local value from store
        this.$emit('input', ecatFormSelect.value); // Emit the value to the parent
        // this.$emit('loaded')
      }
    }
  },

  async mounted() {
    await this.loadValue(); // Load initial value from the store
  }
};
</script>
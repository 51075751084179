export const state = () => ({
    shopId: '',
    shops: [],
    shopsIds: [],
    pairedShops: []
})

export const getters = {
    selectedShopIsVirtual: (state, getters, rootState, rootGetters)=> {
        const shop = state.shops.find(item => item.id === state.shopId);
        return shop ? rootGetters["engine/isVirtual"](shop.engineId) : false
    },

    selectedShopIsWordPress: (state, getters, rootState, rootGetters) => {
        const shop = state.shops.find(item => item.id === state.shopId);
        return shop ? rootGetters["engine/isWordPress"](shop.engineId) : false
    },

    selectedShopIsPaired: state => {
        const pairedShop = state.pairedShops.find(item => item.id === state.shopId)
        return !!pairedShop
    },

    isPairedShop: state => (id) => {
        const pairedShop = state.pairedShops.find(item => item.id === id)
        return !!pairedShop
    },

    shopIsVirtual: (state, getters, rootState, rootGetters) => (id) => {
        const shop = state.shops.find(item => item.id === id);
        return shop ? rootGetters["engine/isVirtual"](shop.engineId) : false;
    },

    shopIsWordPress: (state, getters, rootState, rootGetters) => (id) => {
        const shop = state.shops.find(item => item.id === id);
        return shop ? rootGetters["engine/isWordPress"](shop.engineId) : false;
    },

    selectedShopId: state => state.shopId,
    selectedShop: state => state.shops.find(item => item.id === state.shopId),

    getShops: state => {
        return state.shops
    },

    getShopsIds: (state, getters, rootState, rootGetters) => {
        const market = rootGetters["market/selectedMarket"]
        if (!market) {
            return []
        }

        return state.shopsIds.filter(shopId => {
            const shop = getters.getShops.find(shop => shop.id === shopId)
            if (shop) {
                return shop.marketId === market.id
            }

            return false
        })
    },

    getShop: state => (id) => {
        return state.shops.find(item => item.id === id);
    }

}

export const mutations = {
    SET_SELECTED_SHOP_ID(state, shopId) {
        state.shopId = shopId;
    },

    SET_SHOPS(state, shops) {
        state.shops = shops

        const ids = [];
        for (let shop of shops) {
            ids.push(shop.id);
        }

        state.shopsIds = ids
    },

    SET_PAIRED_SHOPS(state, pairedShops) {
        state.pairedShops = pairedShops
    },

    ADD_SHOP(state, shop) {
        state.shops.push(shop)
        state.shopsIds.push(shop.id)
    }
}

export const actions = {
    setSelectedShopId({commit}, payload) {
        commit('SET_SELECTED_SHOP_ID', payload)
    },

    setShops({commit}, payload) {
        commit('SET_SHOPS', payload)
    },

    setPairedShops({commit}, payload) {
        commit('SET_PAIRED_SHOPS', payload)
    },

    addShop({commit}, payload) {
        commit('ADD_SHOP', payload)
    }
}
<template>
  <div class="col-lg-12 custom-card-subtitle">
    <h5 class="text-start py-2 my-0 px-3 card-subtitle">
      {{ title }}
    </h5>
  </div>
</template>
<script>

export default {
  name: "custom-card-subtitle",

  props: {
    title: {
      type: String,
      required: true
    },
  },

  mounted() {
    this.$root.$on("change-language");
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  }

}
</script>
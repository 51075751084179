<template>
  <div id="app">
    <authorize-2fa-modal ref="authorize2FAModal"/>
    <needs-to-set-full-name-modal ref="needsToSetFullNameModal"/>
    <help-video-balloon v-if="$route.path.includes('/dashboard')"/>
    <tpay-transaction-modal ref="tPayTransactionModal"/>

    <router-view/>
  </div>
</template>

<script>
import {userService} from "@/helpers/user.service";
import Swal from "sweetalert2";
import {routerHelper} from "@/helpers/router-helper";
import axios from "axios";
import {languageHelper} from "@/helpers/language-helper";
import {errorCatcher} from "@/helpers/error-catcher";
import Authorize2faModal from "@/components/profile/authorize-2fa-modal.vue";
import HelpVideoBalloon from "@/components/help-video/help-video-balloon.vue";
import NeedsToSetFullNameModal from "@/components/profile/needs-to-set-full-name-modal.vue";
import TpayTransactionModal from "@/components/payment/tpay-transaction-modal.vue";
import {useUserStore} from "./store/user";

export default {
  name: "app",
  components: {NeedsToSetFullNameModal, Authorize2faModal, HelpVideoBalloon, TpayTransactionModal},

  computed: {
    getSelectedShopId() {
      try {
        const params = routerHelper.params();
        const shopId = params.shopId

        if (shopId) {
          return shopId
        }
      } catch (error) {
        // ignore
      }

      return this.$store ? this.$store.state.shop.shopId : '';
    },

    getSelectedMarketId() {
      return this.$store ? this.$store.state.market.marketId : '';
    }

  },

  methods: {
    async redirect(canRedirect) {
      await userService.loginTwo(false, canRedirect)
    },

    async checks(from, to) {
      if (!from || !to) {
        return
      }

      if (!this.checkPermissions(to)) {
        return
      }

      if (this.checkSelectedShop(from, to)) {
        return
      }

      if (this.checkSelectedMarket(from, to)) {
        return
      }

      if (this.checkPairedShop(from, to)) {
        return
      }

      this.checkOnlyWordPress(from, to)
    },

    checkPermissions(to) {
      const userStore = useUserStore()
      const user = userStore.getUser
      if (user && user.needsToSetFullName) {
        this.$router.push('/').then(() => {
          Swal.fire("", "Nie masz dostępu do tej zakładki!", "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })

        return false
      }

      if (user && !this.$store.getters["grequiredemails/getRequiredEmails"].includes(user.email) && to.path === "/dashboard/admin/aderlo-cashback-panel") {
        this.$router.push('/dashboard/home').then(() => {
          Swal.fire("", "Nie masz dostępu do tej zakładki!", "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })
      }

      if (!to.meta || !to.meta.requiredPermissions) {
        return true
      }

      for (const requirePermission of to.meta.requiredPermissions) {
        if (!this.$store.getters["role/hasPermission"](requirePermission)) {
          this.$router.push('/dashboard/home').then(() => {
            Swal.fire("", "Nie masz dostępu do tej zakładki!", "error");
          }).catch((error) => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
              console.log(error)
            }
          })

          return false
        }
      }

      return true
    },

    checkSelectedShop(from, to) {
      if (this.$store.getters["parameters/getAderloShopId"] === this.getSelectedShopId) {
        return true
      }

      if (this.getSelectedShopId) {
        return false;
      }

      const path = to.path
      if (path === '/dashboard/products-catalog') {
        return false
      }

      if (path.includes('/dashboard/user/shop/products') || path.includes('/dashboard/products')
          || path.includes('/dashboard/e-wallet')
          || path.includes('/dashboard/user/allegro') || path.includes('/dashboard/user/allegro/connect')
          || path.includes('/dashboard/user/allegro/after-sale-service') || path.includes('/dashboard/user/allegro/prices')
          || path.includes('/dashboard/user/allegro/auctions')
          || path.includes('/dashboard/shop/categories')) {
        this.$router.push('/dashboard/home').then(() => {
          Swal.fire("", this.$t('core.select.shop'), "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })

        return true
      }

      return false
    },

    checkSelectedMarket(from, to) {
      if (this.getSelectedMarketId) {
        return false
      }

      const path = to.path;
      if (path.includes('/dashboard/products-catalog')) {
        this.$router.push('/dashboard/home').then(() => {
          Swal.fire("", this.$t('core.select.market'), "error");
        }).catch((error) => {
          if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
            console.log(error)
          }
        })

        return true
      }

      return false
    },

    checkPairedShop(from, to) {
      const shopId = this.getSelectedShopId
      if (!shopId) {
        return false
      }

      const path = to.path
      if (path === '/dashboard/products-catalog') {
        return false
      }

      if (path === "/dashboard/wordpress-not-activated" && this.$store.getters['shop/isPairedShop'](shopId)) {
        routerHelper.push(this.$store.getters["wordpressnotactivated/getPath"])
        return true
      }

      if (!this.$store.getters['shop/shopIsVirtual'](shopId) && this.$store.getters['shop/isPairedShop'](shopId)) {
        return false
      } else if (this.$store.getters['shop/shopIsVirtual'](shopId)) {
        return false
      }

      if (this.$store.getters['shop/shopIsWordPress'](shopId) && !this.$store.getters['shop/isPairedShop'](shopId) && (to.path.includes('/dashboard/user/shop/products') || to.path.includes('/dashboard/products'))) {
        this.$store.dispatch("wordpressnotactivated/setPath", to.path).then(() =>
            this.$router.push('/dashboard/wordpress-not-activated').then(() => {
              // Swal.fire("", this.$t('user-shops.wordpress-is-not-activated'), "error");
            }).catch((error) => {
              if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                console.log(error)
              }
            }))

        return true
      }
    },

    checkOnlyWordPress(from, to) {
      const shopId = this.getSelectedShopId
      if (!shopId) {
        return false
      }

      const isPath = to.path.includes('/dashboard/shop/categories');
      if (this.$store.getters['shop/shopIsVirtual'](shopId)) {
        if (isPath) {
          this.$router.push('/dashboard/home').then(() => {
            Swal.fire("", "Dla sklepu wirtualnego kategorie nie są dostępne!", "error");
          }).catch((error) => {
            if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
              console.log(error)
            }
          })
        }

        return
      }

      if (!this.$store.getters['shop/isPairedShop'](shopId) && this.$store.getters['shop/shopIsWordPress'](shopId) && isPath) {
        this.$store.dispatch("wordpressnotactivated/setPath", to.path).then(() =>
            this.$router.push('/dashboard/wordpress-not-activated').then(() => {
              // Swal.fire("", this.$t('user-shops.wordpress-is-not-activated'), "error");
            }).catch((error) => {
              if (error.name !== 'NavigationDuplicated' && !error.message.includes('Avoided redundant navigation to current location')) {
                console.log(error)
              }
            }))
      }
    },

    async loadLanguageFallbackId() {
      try {
        const {data} = await axios.get(`/parameter/get-language-fallback-id`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setLanguageFallbackId', data.value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadAnalytics() {
      try {
        const {data} = await axios.get(`/parameter/analytics`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setAnalytics', data)

        if (data.facebookPixel.value) {
          window.fbq('init', data.facebookPixel.value);
        }

        if (data.googleTagManager.value) {
          this.initGoogleTagManager(data.googleTagManager.value);
          this.initGoogleTagManagerNoscript(data.googleTagManager.value)
        }

        if (data.googleAnalytics.value) {
          this.$loadScript(`https://www.googletagmanager.com/gtag/js?id=${data.googleAnalytics.value}`)
          this.initGoogleAnalytics(data.googleAnalytics.value)
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadReCaptchaSiteKeyParameter() {
      try {
        const {data} = await axios.get(`/parameter/get-recaptcha-site-key`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch('parameters/setReCaptchaSiteKey', data.value)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadTranslations(fallback) {
      let id = this.$store.getters['parameters/getLanguageFallbackId']
      if (!fallback) {
        id = this.$store.getters['translation/getCurrentLocale']
      }

      try {
        const {data} = await axios.get(`/translation/by-language-id/${id}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        const translations = languageHelper.transformJSONToMap(JSON.parse(data.translations));

        await this.$store.dispatch(fallback
                ? "translation/setTranslationsFallback"
                : "translation/setTranslations",
            translations
        );
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadLanguages() {
      try {
        const {data} = await axios.get(`/language`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        await this.$store.dispatch("translation/setLanguages", data)
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    loadScripts() {
      this.reloadScript("https://www.google.com/recaptcha/api.js")
      this.$forceUpdate()
    },

    initGoogleTagManager(gtmCode) {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmCode}');
      `;

      document.head.appendChild(script);
    },

    initGoogleTagManagerNoscript(gtmCode) {
      const noscript = document.createElement('noscript');
      const iframe = document.createElement('iframe');

      iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmCode}`;
      iframe.height = "0";
      iframe.width = "0";
      iframe.style.display = "none";
      iframe.style.visibility = "hidden";

      noscript.appendChild(iframe);
      document.body.appendChild(noscript);
    },

    initGoogleAnalytics(code) {
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag('js', new Date());
      gtag('config', code);
    },

    reloadScript(url) {
      try {
        this.$unloadScript(url)
        this.$loadScript(url)
            .then(() => this.$store.dispatch("recaptcha/setScriptLoaded"))
      } catch (error) {
        console.log(error)
      }
    }

  },

  watch: {
    $route: {
      immediate: true,
      async handler(to, from) {
        this.loadScripts()
        await this.redirect(to.path.includes('/dashboard'))
        await this.checks(from, to)
      }
    }
  },

  async created() {
    await this.$store.dispatch('theme/updateTheme')
    await this.loadReCaptchaSiteKeyParameter()
    await this.loadLanguageFallbackId()
    await this.loadAnalytics()
    await this.loadTranslations(true)
    await this.loadTranslations(false)
    await this.loadLanguages()
  },

  mounted() {
    this.$root.$on("ecat-authorize-2fa-enable-modal", () => this.$refs.authorize2FAModal.openModal())
    this.$root.$on("needs-to-set-full-name-modal", () => this.$refs.needsToSetFullNameModal.openModal())
    this.$root.$on("open-tpay-transaction-modal", (internalOrder) => this.$refs.tPayTransactionModal.openModal(internalOrder))
  },

  beforeDestroy() {
    this.$root.$off("ecat-authorize-2fa-enable-modal");
    this.$root.$off("needs-to-set-full-name-modal");
    this.$root.$off("open-tpay-transaction-modal");
  }

};
</script>

<style lang="scss">
body {
  background-color: var(--body-bg) !important;
}

thead {
  background-color: var(--table-head-color) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.mx-input {
  background-color: var(--form-control-bg) !important;
  color: var(--form-control-text) !important;
  border: 1px solid var(--form-control-border) !important;
}

.mx-icon-calendar, .mx-icon-clear {
  color: var(--form-control-text) !important;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

*::-webkit-scrollbar-thumb {
  background: var(--primary);
  border: solid 3px #202020;
  border-radius: 7px;
}

*::-webkit-scrollbar-thumb:hover {
  background: darken(#4892F1, 10%);
}

.auth-form-group-custom label {
  color: var(--login-input-header-color) !important;
}

.auth-form-group-custom input {
  background-color: var(--login-input-bg-color) !important;
  border: 1px solid var(--login-input-border-color) !important;
}

.custom-login-btn {
  border: 1px solid var(--custom-login-btn-border-color) !important;
  color: var(--custom-btn-login) !important;
  background-color: transparent !important;

  img {
    position: absolute;
    left: 20px;
  }
}

.btn-outline {
  border: 1px solid var(--gray-900) !important;
  color: var(--gray-900) !important;
}

.button-icon {
  padding: 14px 0 !important;
  font-size: 14px !important;
  font-weight: var(--font-semibold) !important;

  img, i {
    position: absolute;
    left: 20px;
  }

  img {
    font-size: 20px;
  }

  i {
    top: 7px;
    font-size: 24px;
  }
}

.auth-form-group-custom .auti-custom-input-icon {
  position: absolute;
  display: inline-block;
  margin-bottom: 0.5rem;
  top: 14px !important;
  transform: unset !important;
}

.clickable-element {
  cursor: pointer;
}

.btn {
  cursor: pointer;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.multiselect__content-wrapper {
  min-width: 230px;
  z-index: 50 !important;
}

.multiselect__single {
  width: 90% !important;

  img {
    margin-right: 5px !important;
  }
}

.swal2-container {
  z-index: 999999 !important;
}

.multiselect__option img {
  margin-right: 5px !important;
}


.multiselect__select:before {
  top: 50% !important;
}


.multiselect__option--selected {
  background: var(--topbar-search-bg) !important;
  color: var(--form-control-text);
  z-index: 50 !important;
}

.vue-country-select .dropdown:hover {
  background-color: var(--topbar-search-bg) !important;
}

.vue-country-select {
  border: 1px solid var(--dropdown-border-color) !important;
  border-top-color: var(--dropdown-border-color) !important;
  border-right-color: var(--dropdown-border-color) !important;
  border-bottom-color: var(--dropdown-border-color) !important;
  border-left-color: var(--dropdown-border-color) !important;
}

.vue-country-select > .dropdown > .dropdown-list > .dropdown-item:hover, .dropdown-item:focus {
  color: var(--focus-color) !important;
}

.vue-country-select > .dropdown > .dropdown-list > .dropdown-item {
  background-color: var(--topbar-search-bg) !important;
}

.vue-country-select .dropdown.open {
  background-color: var(--topbar-search-bg) !important;
}

.vue-country-select .dropdown-list {
  background-color: var(--topbar-search-bg) !important;
  border: 1px solid var(--topbar-search-bg) !important;
}

.custom-control-label::after {
  background-color: #d7e4ec !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%235664d2' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important;
}

.table th, .table td {
  padding-left: 15px !important;
}


body div.multistore-modal .modal-dialog {
  width: 1600px !important;
  max-width: 1600px !important;
}

@media (max-width: 576px) {
  body div.multistore-modal .modal-dialog {
    width: 380px !important;
    max-width: 380px !important;
  }
}

.close {
  color: var(--icon-close) !important;
}

.ck-body-wrapper {
  z-index: 9999999 !important
}

.ck-balloon-rotator {
  position: fixed !important;
  background-color: var(--gray-700) !important;
}

.ck.ck-balloon-panel.ck-balloon-panel_with-arrow:after, .ck.ck-balloon-panel.ck-balloon-panel_with-arrow:before {
  content: none !important;
}

.svg-filter {
  filter: var(--shop-filter);
}

.line {
  border: 1px solid var(--login-line-color);
  width: 50%;
  height: 0;
}

.custom-modal-title {
  color: var(--modal-title);
}

.custom-card-title {
  font-size: 14px;
  margin: 20px;
  font-weight: var(--font-regular);
  letter-spacing: 0.21px;

  color: var(--font-color);
}

.w-xxl {
  min-width: 220px;
}

.w-49 {
  width: 49% !important;
}

.w-32 {
  width: 32% !important;
}


.b-table-has-details td {
  position: relative !important;
}

.b-table-has-details td div {
  position: absolute !important;

  width: 100%;
  height: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.default-order > td[aria-colindex="1"] {
  width: 20% !important;
}

.default-order > td[aria-colindex="2"] {
  width: 5% !important;
}

.default-order > td[aria-colindex="3"] {
  width: 10% !important;
}

.default-order > td[aria-colindex="4"] {
  width: 10% !important;
}

.default-order > td[aria-colindex="5"] {
  width: 10% !important;
}

.default-order > td[aria-colindex="6"] {
  width: 20% !important;
}

.b-table-has-details td[aria-colindex="1"] {
  width: 20% !important;
}

.b-table-has-details td[aria-colindex="2"], .b-table-has-details td[aria-colindex="3"], .b-table-has-details td[aria-colindex="5"] {
  width: 10% !important;
}

.b-table-has-details td[aria-colindex="4"] {
  width: 15% !important;
}

.b-table-has-details td[aria-colindex="6"] {
  width: 15% !important;
}

@media (min-width: 768px) and (max-width: 2300px) {
  .b-table-has-details td[aria-colindex="6"] {
    width: 30% !important;
  }
}

.status-data span {
  position: relative;
  color: white;
  font-weight: var(--font-medium);
  margin-left: 10px;
}

.status-data .actions {
  margin-left: 10px;
}

.table tbody tr, .table tbody td {
  border: 1px solid var(--table-border-color) !important;
}

.authentication-page-content {
  background-color: var(--login-bg);
}

.authentication-bg {
  background-image: var(--login-bg-image) !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;

}

.bg-overlay {
  background-color: var(--login-bg-color) !important;
}

.card {
  background-color: var(--card-bg) !important;
}

.card-selectors {
  min-width: 100%;

  display: flex;
  flex-direction: row;
}

.input-container.input-disabled input {
  background-color: transparent !important;
  border: 1px solid #373E4C !important;
  color: #9398AB !important;
}

@media (max-width: 1280px) {
  .card-button {
    border: 1px solid #373E4C;
    border-radius: 5px;

    margin: 0 25px 10px 25px;
  }

  .card-button:first-child {
    margin-top: 10px;
  }

  .card-button.active {
    border-radius: 5px !important;
  }

  .card-buttons {
    background-color: var(--card-bg) !important;

    border-radius: 5px 5px 0 0;
  }
}

.card-button:hover {
  cursor: pointer;
}

.card-button {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;

  font-weight: bold;
  font-size: 12px;
}

.card-button.active {
  background-color: var(--product-card-btn-active-bg) !important;

  border-radius: 5px 5px 0 0;
}

.card-button.active i {
  color: var(--product-card-btn-active);
}

.card-button.active .text {
  color: var(--product-card-btn-active-subtitle);
}

.modal-backdrop {
  background: rgba(10, 13, 19, 0.42) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}

.table {
  color: var(--table-color) !important;
  background-color: var(--table-bg-color) !important;
}

.dataTables_length, .dataTables_filter {
  label {
    margin-bottom: 0 !important;
    font-size: 15px;
    font-weight: var(--font-regular);
    color: var(--input-color);
  }

  select, input {
    border: 1px solid var(--table-border-color);
  }
}

ol, ul, dl {
  margin-bottom: 0 !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.modal-header {
  color: var(--gray-900) !important;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: var(--font-bold);

  border-bottom: 1px solid var(--gray-300) !important;
}

.modal-content {
  background-color: var(--card-bg) !important;
  border: 1px solid var(--gray-300) !important;
}

.shop-header {
  background-color: var(--shop-bg);
}

.product-header-title {
  font-size: 18px !important;
}

.card-custom-header {
  background-color: var(--card-header-bg);
}

.card-custom-footer {
  background-color: var(--card-footer-bg) !important;

  padding: 20px 10px !important;
}

.header-card-icon-bg {
  background-color: var(--product-card-header-bg);

  min-height: 100%;
  width: 100vh;
  position: absolute;
}

@media (max-width: 1200px) {
  .product-info {
    width: 100%;

    padding: 0;
  }
}

.product-info {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: normal;

  border-radius: 14px;

  padding: 10px 15px;
}

.product-detail-item {
  font-family: Inter, sans-serif;
  font-size: 12px;

  font-weight: bold;
  color: var(--product-card-detail);
}

.product-detail-item .subtitle {
  font-weight: lighter;
  color: var(--product-card-details-subtitle);
}

.product-body-title {
  font-family: Inter, sans-serif;
  font-weight: bold;
  font-size: 18px;

  color: var(--product-card-body);
}

.product-more-info {
  font-weight: normal;
  font-size: 18px;
  color: var(--product-card-target-subtitle);

  cursor: pointer;
}

.product-send-target {
  font-weight: normal;
  font-size: 11px;
  color: var(--product-card-target);
}

.product-send-target .subtitle {
  font-weight: bold;
  font-size: 12px;
  color: var(--product-card-target-subtitle);
}

.btn-green {
  background-color: #1CBB8C !important;
  color: #FFFFFF !important;
}

.btn-outline-orange {
  color: #F89157 !important;
  border-color: #F89157 !important;
}

.btn-outline-gray {
  color: var(--product-card-btn-gray) !important;
  border-color: var(--product-card-btn-gray-border) !important;
}

.btn-outline-yellow {
  color: #FCB92C !important;
  border-color: #FCB92C !important;
}

.product-card-body {
  background-color: var(--product-card-body-bg);
}

.product-card-select {
  background-color: var(--product-card-select-bg);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px 0 0 0;
}

.product-card-select.selected {
  background-color: var(--product-card-select-selected-bg);
}

.card-option {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-height: 60px;

  font-size: 12px;
  font-weight: lighter;
  color: var(--product-card-option);

  background-color: var(--product-card-option-bg);

  border-top: 2px solid var(--product-card-option-border);
  border-right: 1px solid var(--product-card-option-border);
}

.product-card-image {
  border-width: 1px 1px 0 1px;
  border-color: var(--product-card-option-image-border);
  border-style: solid;
}

.product-card-body {
  border-width: 1px 1px 0 1px;
  border-color: var(--product-card-body-border);
  border-style: solid;

  border-radius: 5px 5px 0 0;
}

.card-option.highlight {
  background-color: var(--product-card-option-highlight) !important;
}

.card-option.highlight span {
  color: var(--product-card-option-highlight-subtitle);
}

.card-option span {
  font-size: 12px;
  font-weight: bold;
  color: var(--product-card-option-subtitle);

  margin-right: 5px;
}

.card-option:first-child {
  border-radius: 0 0 0 5px;
}

.card-option:last-child {
  border-right: none;
  border-radius: 0 0 5px 0;
}

.product-card-actions {
  background: var(--product-card-option-actions-bg);

  display: flex;
  flex-direction: column;
  justify-content: center;

  border-radius: 0 5px 0 0;

  padding: 10px 20px;
}

.product-card-actions .btn {
  min-width: 100%;
  padding: 10px 15px;
  margin: 5px auto;
}

.product-edit {
  font-size: 12px;
  font-weight: normal;
  color: var(--product-card-option-edit);
  max-width: 150px;
}

@media (min-width: 1280px) {
  .product-detail-item:first-child {
    border-radius: 5px 0 0 5px;
  }

  .product-detail-item:last-child {
    border-right: none;
    border-radius: 0 5px 5px 0;
  }

  .product-detail-item {
    margin-bottom: 0;
  }
}

.product-detail-item {
  background-color: var(--product-card-detail-bg);
  min-height: 40px;
  display: flex;
  align-items: center;

  border-right: 2px solid var(--product-card-detail-border);
}

.product-detail-item {
  margin-bottom: 2px;
}

.product-detail-item:nth-child(1) {
  border-radius: 5px 0 0 0;
}

.product-detail-item:nth-child(2) {
  border-radius: 0 5px 0 0;
}

.product-detail-item:nth-child(3) {
  border-radius: 0 0 0 5px;
}

.product-detail-item:nth-child(4) {
  border-radius: 0 0 5px 0;
}

.product-advanced-detail-items {
  background-color: var(--product-card-advanced-detail-bg);

  border-right: 1px solid var(--product-card-advanced-detail-border);

  width: 100%;
}

.product-advanced-detail-items:first-child {
  border-radius: 5px 0 0 5px;
}

.product-advanced-detail-items:last-child {
  border-right: none;
  border-radius: 0 5px 5px 0;
}

.product-card-body {
  padding: 15px;
}

.info-green {
  background-color: rgba(113, 224, 137, 0.17);
  color: var(--product-card-info);

  .subtitle {
    color: rgba(113, 224, 137, 1);
  }
}

.info-warning {
  background-color: rgba(224, 193, 113, 0.17);
  color: var(--product-card-info);

  .subtitle {
    color: rgb(224, 198, 113);
  }
}


.info-danger {
  background-color: rgba(224, 113, 113, 0.17);
  color: var(--product-card-info);

  .subtitle {
    color: rgb(224, 113, 113);
  }
}

.decoration-underline {
  text-decoration: underline;
}

.info-orange {
  background-color: rgba(248, 145, 87, 0.17);
  color: var(--product-card-info);

  .subtitle {
    color: rgba(248, 145, 87, 1);
  }
}

.product-info .subtitle {
  font-weight: bold;
  margin-left: 4px;
}

.product-detail-item .subtitle {
  margin-left: 4px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-32 {
  font-size: 32px;
}

.list-group-item {
  color: var(--plan-item) !important;
  font-weight: var(--font-light);
  font-size: 14px;
  background-color: transparent !important;
}

.form-control {
  background-color: var(--form-control-bg) !important;
}

.form-control, .custom-select {
  color: var(--form-control-text) !important;
  border: 1px solid var(--form-control-border) !important;
}

.cashback-container-body {
  background: var(--cashback-body);
}

.cashback-top {
  background: var(--cashback-top);
}

.cashback-bottom {
  background: var(--cashback-bottom);
}

.cashback-chart {
  mix-blend-mode: var(--cashback-chart-mode);
  filter: var(--cashback-chart-filter)
}

.cashback-box {
  border: 1px solid var(--cashback-border);
}

.withdraw-box-info {
  background: #2A313E;
  border: 1px solid #363F51;
}

.custom-select {
  background: var(--form-control-bg) var(--custom-select-icon) no-repeat right 0.75rem center/8px 10px !important;
}

.custom-select:focus-visible {
  background: var(--form-control-bg) var(--custom-select-icon-focus) no-repeat right 0.75rem center/8px 10px !important;
}

.card-custom-body-header {
  margin-top: 10px;
}

.card-custom-title {
  margin-bottom: 10px;
}

.card-custom-button {

  a {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.card-custom-type {
  /*  display: inline-flex;
    align-items: center;*/
  //padding-top: 4px !important;
}

.form-xs {
  height: 38px !important;
  width: 50px !important;
}

.badge {
  padding: 0.3em 1.4em !important;
}

.price {
  color: var(--price-color);
  text-align: center;

  font-size: 16px;
  font-weight: var(--font-medium);
  width: 25% !important;
  border-left: 1px;
}

.price-highlight-color {
  color: var(--pricing-enterpirse-highlight) !important;
}

.pricing-duration {
  color: var(--pricing-duration) !important;
}

.pricing-duration-highlight {
  color: var(--pricing-duration) !important;
}


.page-item.disabled .page-link {
  color: var(--pagination) !important;
  background-color: var(--pagination-bg-color) !important;
  border-color: var(--pagination-border-color) !important;
}

.page-link {
  color: var(--pagination) !important;
  background-color: var(--pagination-bg-color) !important;
  border: 1px solid var(--pagination-border-color) !important;
}

.page-item.active .page-link {
  color: var(--pagination-active) !important;
  background-color: var(--pagination-active-bg-color) !important;
  border-color: var(--pagination-active-bg-color) !important;
}

.dropdown-menu {
  background-color: var(--card-bg) !important;
  border: 1px solid var(--gray-300) !important;
}

.custom-number-with-gap::-webkit-inner-spin-button,
.custom-number-with-gap::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button !important;
  opacity: 1;
}

.custom-number-with-gap {
  -moz-appearance: textfield;
}

.custom-number-with-gap::-moz-inner-spin-button,
.custom-number-with-gap::-moz-outer-spin-button {
  -moz-appearance: inner-spin-button !important;
}

.custom-number-with-gap::-ms-clear {
  display: none;
}

.custom-number-with-gap::-ms-inner-spin-button,
.custom-number-with-gap::-ms-outer-spin-button {
  -ms-appearance: inner-spin-button !important;
  opacity: 1;
}

.pricing-card .card.popular {
  border: 1px solid var(--blue);
}

.pricing-card .annual-plan .plan-price span {
  font-size: 3.5rem;
  line-height: 0.8;
}

.pricing-card .annual-plan .plan-price sub {
  bottom: 0;
  right: 0.14rem;
}

.pricing-card .annual-plan .plan-price sup {
  top: -1.5rem;
  left: 0.2rem;
}

.pricing-card .annual-plan .annual-pricing {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
}

.popular-pricing-card {
  border: 1px solid #4892F1;
  background-color: rgba(72, 146, 241, 0.25) !important;
  color: #E7EAF4;
}

.custom-card-subtitle {
  margin-bottom: 10px !important;
}

.hover-test:hover {
  span svg path {
    fill: #4892F1;
    fill-opacity: 1;
  }

  .product-title-color {
    color: var(--card-hover-color);
  }
}

.product-description-color {
  color: var(--product-)
}

.product-selected {
  color: var(--card-selected) !important;
}

.card-subtitle {
  color: var(--custom-card-subtitle-color);
  font-size: 12px;
  border-radius: 5px;
  font-weight: var(--font-bold);
  background-color: var(--custom-card-subtitle-bg);
}

.color-text-white {
  color: #E7EAF4;
}

.color-primary {
  color: var(--color-primary)
}

.color-secondary {
  color: var(--color-secondary);
}

.card-custom {
  background-color: var(--card-plan-bg);
  border-radius: 5px;
}

.annual-plan {
  background-color: var(--anual-plan-bg);
}

.highlight {
  background-color: #4892F1 !important;
}

.pricing-enterprise-value {
  color: var(--pricing-enterpirse);
  font-size: 2.9rem !important;
  font-weight: var(--font-medium);
}

.font-medium-1 {
  font-size: 1.1rem !important;
}

.list-group.list-group-circle .list-group-item {
  border: none;
  position: relative;
  padding-left: 1.5rem;
}

.list-group .list-group-item {
  line-height: 1.5;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(34, 41, 47, 0.125);
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem 1.5rem !important;
}

.newsletter-box {
  flex: 1 1 auto;
  min-height: 1px;
}

.card-plan {
  background-color: var(--card-plan-bg);

  padding: 20px 0 !important;

  border-radius: 5px !important;
}

.card-custom-body {
  background-color: var(--card-bg) !important;
}

/*.card-custom-data {
  height: 55px;
}*/

.square_shape {
  background-color: var(--card-image-bg);
  width: 80px;
  height: 80px;
  border-radius: 5px;
}


.btn-category-selected {
  border-radius: 5px;

  border: 1px solid var(--btn-category-selected-border) !important;

  background: var(--btn-category-selected-bg) !important;

  .text, i {
    color: var(--btn-category-selected) !important;
  }
}

.input-selected {
  border: 1px solid #4892f1 !important;
  border-radius: 5px !important;
}

.btn-category-not-selected {
  border-radius: 5px !important;

  border: 1px solid var(--btn-category-notselected-border) !important;

  background: var(--btn-category-notselected-bg) !important;

  .text, i {
    color: var(--btn-category-notselected) !important;
  }
}

.btn-product-details {
  border-radius: 5px !important;

  border: 1px solid var(--product-btn-border-color) !important;

  background: transparent !important;

  color: var(--product-btn-color) !important;
}

.product-header-image {
  border-radius: 5px;

  width: 65px !important;
  height: 65px !important;
}

.custom-image {
  width: inherit;
  height: inherit;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.card-custom-image {
  height: 100px !important;
}

.plan-title {
  color: var(--plan-title);
  font-weight: var(--font-bold);
  font-size: 14px;
}

.plan-subtitle {
  color: var(--plan-subtitle);
  font-size: 12px;
  font-weight: var(--font-light);
}

.status-bg-licence {
  background-color: var(--status-licence-bg);
}

.status-bg-active-wordpress {
  background-color: var(--status-wordpress-active-bg);
}

.status-bg-inactive-wordpress {
  background-color: var(--status-wordpress-inactive-bg);
}

.status-text-licence {
  color: #FFFFFF;
}

.status-text-active-wordpress {
  color: var(--status-wordpress-active-color);
}

.status-text-inactive-wordpress {
  color: var(--status-wordpress-inactive-color);
}

.status-text-1 {
  color: #c58900;
}

.status-text-2 {
  color: #4fc500;
}

.status-text-3 {
  color: #358600;
}

.status-text-4 {
  color: #ee7700;
}

.status-text-5 {
  color: #00ee6f;
}

.status-text-6 {
  color: #3d74ff;
}

.status-text-7 {
  color: #00ff95;
}

.status-text-8 {
  color: #00ff29;
}

.status-text-9 {
  color: #ff00a9;
}

.status-text-10 {
  color: #b87cff;
}

.status-text-11 {
  color: #ff0000;
}

.status-text-12 {
  color: #00eaff;
}

.status-text-13 {
  color: #0048ff;
}

.status-text-14 {
  color: #bdda00;
}

.status-text-15 {
  color: #1DB100;
}

.status-text-16 {
  color: #a300d9;
}

.status-text-17 {
  color: #1cbb8c;
}

.status-text-18 {
  color: #ff3d60;
}

.status-background-1 {
  background-color: rgba(197, 137, 0, 0.6);
}

.status-background-2 {
  background-color: rgba(79, 197, 0, 0.6);
}

.status-background-3 {
  background-color: rgba(53, 134, 0, 0.6);
}

.status-background-4 {
  background-color: rgba(238, 119, 0, 0.6);
}

.status-background-5 {
  background-color: rgba(0, 238, 111, 0.6);
}

.status-background-6 {
  background-color: rgba(61, 116, 255, 0.6);
}

.status-background-7 {
  background-color: rgba(0, 255, 149, 0.6);
}

.status-background-8 {
  background-color: rgba(0, 255, 41, 0.6);
}

.status-background-9 {
  background-color: rgba(255, 0, 169, 0.6);
}

.status-background-10 {
  background-color: rgba(184, 124, 255, 0.6);
}

.status-background-11 {
  background-color: rgba(255, 0, 0, 0.6);
}

.status-background-12 {
  background-color: rgba(0, 234, 255, 0.6);
}

.status-background-13 {
  background-color: rgba(0, 72, 255, 0.6);
}

.status-background-14 {
  background-color: rgba(189, 218, 0, 0.6);
}

.status-background-15 {
  background-color: rgba(29, 177, 0, 0.6);
}

.status-background-16 {
  background-color: rgba(163, 0, 217, 0.6);
}

.status-background-17 {
  background-color: rgba(28, 187, 140, 0.6);
}

.status-background-18 {
  background-color: rgba(255, 61, 96, 0.6);
}

.badge-custom-purple {
  background-color: rgba(119, 113, 224, 0.17);
  color: #7771E0;
}

.badge-custom-yellow {
  background-color: rgba(252, 185, 44, 0.17);
  color: #FCB92C;
}

.badge-custom-aqua {
  background-color: rgba(14, 112, 154, 0.17);
  color: #0E709A
}

.badge-custom-light-purple {
  background-color: rgba(140, 117, 197, 0.17);
  color: #8c75c5;
}

.badge-custom-dark-purple {
  background-color: rgba(143, 50, 168, 0.1);
  color: #8f32a8;
}

.badge-custom-deep-teal {
  background-color: rgba(58, 123, 148, 0.17);
  color: #3a7b94;
}

.badge-custom-pink {
  background-color: rgba(154, 113, 224, 0.17);
  color: #e071b4;
}

.badge-custom-gray {
  background-color: rgba(187, 179, 201, 0.17);
  color: #9b9b9b;
}

.badge-custom-sea {
  background-color: rgba(0, 128, 128, 0.17);
  color: #008080;
}

.badge-custom-sapphire {
  background-color: rgba(37, 84, 199, 0.17);
  color: #2554C7;
}

.selected-button {
  background-color: #3848ca !important;
  border-color: #b2b2b2 !important;
}

.badge-shop-type {
  border-radius: 15px !important;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.10);
}

.product-header-title {
  color: var(--product-header-color);
}

.product-title-color {
  color: var(--product-title-color);
}

.product-description-color {
  color: var(--product-description-color);
}

.product-header {
  background-color: var(--product-header-bg) !important;
}

.product-body {
  background-color: var(--product-body-bg) !important;
}

.btn-select-all {
  border-radius: 5px;

  border: none !important;

  background-color: var(--btn-select-all-bg) !important;

  .text, i {
    color: var(--btn-select-all-color) !important;
  }
}

.input-radio {
  font-size: 3em;
  min-width: 20px !important;
  min-height: 20px !important;
  max-width: 20px !important;
  max-height: 20px !important;

  border: 4px solid #191D25;
  background-clip: border-box;
  border-radius: 50%;
  appearance: none;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.img-info {
  max-height: 65px;
  border-radius: 20%;
}

.input-radio:checked {
  box-shadow: 0 0 0 1px #4892F1;
  background-color: #4892F1;
}

.input-radio:not(:checked) {
  box-shadow: 0 0 0 1px #373E4C;
  background-color: #191D25;
}

.btn-select-all:focus, .btn-category-selected:focus, .btn-category-not-selected:focus {
  box-shadow: none !important;
}

.product-card {
  border-radius: 5px;

  border: 1px solid var(--product-boder-color);
}


.buttons {
  .btn {
    margin-bottom: 20px !important;
  }
}

.iframe-container {
  position: absolute;
  z-index: 2;
  top: 32px;
  left: 107px;
}

.ecat-overlay-title {
  font-size: 30px;
  font-weight: var(--font-regular);
  color: #E7EAF4;
}

.ecat-overlay-subtitle {
  color: var(--login-overlay-subtitle);
}

.ri-discord-fill {
  color: var(--header-item-color);
}

.ecat-overlay {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.b-table-sticky-header, .table-responsive, [class*=table-responsive-] {
  margin-bottom: 0 !important;
}

.ecat-overlay-laptop {
  position: relative;
}

.overlay-title-highlight {
  color: var(--login-title-highlight);
}

.highlighted-product {
  background-color: #5664d2;
  color: #d7e4ec;
}

.multiselect__spinner {
  background: transparent !important;
}

.multiselect--disabled {
  background: transparent !important;
}

.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: transparent !important;
}

.ecat-moving-text-container {
  overflow: hidden;
  width: 100%;
  text-align: right;
}

.newsletter-header {
  color: var(--newsletter-header);
  font-size: 20px;
  font-weight: var(--font-bold);
}

.newsletter-content {
  color: var(--newsletter-content);
  font-size: 12px;
  font-weight: var(--font-regular);
}

.newsletter-body {
  height: 263px;
}

.newsletter-body::-webkit-scrollbar {
  width: 5px;
}

.newsletter-body::-webkit-scrollbar-track {
  background: var(--card-bg);
  border-left: none;
}

.newsletter-body::-webkit-scrollbar-thumb {
  background: var(--newstletter-scroll);
  border: none;
  border-radius: 7px;
}

.newsletter-body::-webkit-scrollbar-thumb:hover {
  background: var(--newstletter-scroll)
}

.newsletter-image {
  img {
    border-radius: 5px 0 0 5px;
  }
}

.newsletter {
  background-color: var(--card-bg);
}

.ecat-moving-text {
  white-space: nowrap;
  font-weight: var(--font-semibold);
  display: inline-block;
  animation: ecat-moving 60s linear infinite;
}

#ecat-moving-text-topbar {
  right: 0;
  left: 0;
  margin-bottom: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.g-recaptcha {
  margin: 0 auto;
  width: fit-content;
}

.googleLoginBtn {
  margin: 0 auto;
  width: fit-content;
}

.table.b-table > thead > tr > [aria-sort=none], .table.b-table > tfoot > tr > [aria-sort=none] {
  background-image: var(--table-icon-sort-none) !important;
}

.table.b-table > thead > tr > [aria-sort=ascending], .table.b-table > tfoot > tr > [aria-sort=ascending] {
  background-image: var(--table-icon-sort-asc) !important;
}

.table.b-table > thead > tr > [aria-sort=descending], .table.b-table > tfoot > tr > [aria-sort=descending] {
  background-image: var(--table-icon-sort-desc) !important;
}

.parser-highlight-text {
  background-color: yellow !important;
}

.menu {
  display: none !important;
}

.buttons-area {
  display: inline-flex !important;
}

.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;

  background: rgba(10, 13, 19, 0.42) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  opacity: 1 !important;
}

.center-logo-header {
  display: none;
}

.multiselect__single {
  overflow: hidden;
  white-space: nowrap;
}

.bg-img {
  background-size: contain;
  background-repeat: no-repeat;
}

.b-toaster {
  z-index: 99999 !important;
}

.multiselect__content-wrapper {
  overflow-x: hidden !important;
  white-space: nowrap;
}

@keyframes ecat-moving {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100vw);
  }
}

@media (max-width: 1199px) {
  .newsletter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .newsletter-image {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .newsletter-box {
    padding: 5px 0;
  }

  .newsletter-image img {
    border-radius: 0 !important;
  }

  .newsletter-image {
    height: calc(320px * 0.6);
  }
}

@media (max-width: 1550px) and (min-width: 1280px) {
  .button-icon {
    font-size: 11px !important;

    & img {
      height: 18px;
    }
  }
}

@media (max-width: 992px) {
  .app-search, .navbar-brand-box {
    display: none !important;
  }

  .navbar-header {
    height: 63px !important;
  }

  .menu {
    display: block !important;
  }

  .buttons-area {
    display: none !important;
  }

  .vertical-menu {
    top: 0 !important;
  }

  .bar {
    height: calc(100% - 240px) !important;
  }

  .center-logo-header {
    display: table;
    margin: 0 auto;
  }

  .sidebar-close-btn:hover {
    cursor: pointer;
  }

  .sidebar-close-btn {
    position: fixed;
    top: 15px;
    left: 265px;
    z-index: 10;

    border-radius: 5px;
    width: 30px !important;
    height: 30px !important;
    background-color: rgba(237, 43, 78, 0.50) !important;

    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      color: #FFF;
    }
  }

  .notifications-dropdown-menu {
    transform: translate3d(-173px, -297px, 0px) !important;
  }

  .multiselect__content {
  }

  .multiselect__element {

  }

  .multiselect__option {
    span {
      font-size: 14px;
      display: inline-block !important;
    }
  }

  .product-header-title {
    font-size: 14px !important;
  }
}

.active-package {
  background: #18a689;
  border: #18a689;
  color: white;
}

.package-type {
  width: 100px;
  height: 100px;
  font-size: 50px;
  margin: 10px;
}

.wrap-text {
  word-wrap: break-word;
  word-break: break-all;
}

.custom-row-width {
  width: 250px !important;
}

.text-gray-700 {
  color: var(--gray-500)
}

.text-gray-900 {
  color: var(--gray-900)
}

.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}

.app-content {
  padding: 24px 16px;
}

.parser-rectangle-table {
  justify-content: center;
  max-height: 600px;
  min-height: 600px;
  overflow: auto;
}

.parser-rectangle-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ecat-text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
  width: 80%;
  display: inline-block;
}

.text-underline {
  border-bottom: 1px dotted #ffffff;
}

.custom-textarea {
  background-color: var(--product-card-details-textarea-bg);

  border-radius: 5px;
  border: 1px solid var(--product-card-details-textarea-border);

  padding: 10px;

  font-size: 14px;
  font-weight: normal;
  color: var(--product-card-details-textarea);
}

.custom-textarea::placeholder {
  font-size: 14px;
  font-weight: normal;
  color: var(--product-card-details-textarea-placeholder);
}

.textarea-footer {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: end;
}

.textarea-container {
  border-radius: 5px;

  background-color: var(--product-card-details-textarear-container-bg);

  border: 1px solid var(--product-card-details-textarea-container-border);
}

.footer-item {
  font-size: 12px;
  font-weight: 500;

  color: var(--product-card-details-footer);
  text-decoration: underline;

  padding: 10px;
}

.footer-item:hover {
  cursor: pointer;
}

.footer-item.highlight {
  background-color: transparent !important;
  color: var(--product-card-details-footer-higlight);
}

.footer-item.highlight-blue {
  background-color: transparent !important;
  color: var(--product-card-details-footer-higlight-blue);
}

@media (min-width: 1280px) {
  .modal-xl {
    max-width: 1400px !important;
  }
}
</style>
<template>
  <b-button ref="button" :variant="loading ? `secondary` : `${variant}`" :class="size ? `btn-${size}` : ''" @click="startAsyncTask" :disabled="loading" >
    <span v-if="!loading">
      <slot />
      {{ message }}
    </span>
    <span v-else aria-hidden="true" class="spinner-border text-center text-success" />
  </b-button>
</template>

<script>
export default {

  props: {
    callback: {
      type: Function,
      required: true,
    },

    message: {
      type: String,
      default: ""
    },

    variant: {
      type: String,
      default: ""
    },

    size: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      loading: false
      // width: null,
      // height: null
    }
  },

  methods: {
    async startAsyncTask() {
      this.loading = true

      try {
        await this.callback()
      } catch (error) {
        this.loading = false
      } finally {
        this.loading = false
      }
    },

    // css() {
    //   if (!this.width || !this.height) {
    //     return ''
    //   }
    //
    //   return `min-width: ${this.width}px !important; min-height: ${this.height}px !important; max-width: ${this.width}px !important; max-height: ${this.height}px !important;`
    // }
  },

  // created() {
  //   this.$nextTick(() => {
  //     this.width = this.$refs.button.offsetWidth + 1
  //     this.height = this.$refs.button.offsetHeight + 1
  //   });
  // }

};
</script>
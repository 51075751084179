export const state = () => ({
    engines: [],
    enginesIds: []
})

export const getters = {
    getEngineName: state => (id) => {
        const engine = state.engines.find(item => item.id === id)
        if (!engine) {
            return ""
        }

        return engine.i18nTranslation
    },

    isVirtual: (state, getters, rootState, rootGetters) => (id) => rootGetters["engine/getEngineName"](id) === "engine.virtual",
    isWordPress: (state, getters, rootState, rootGetters) => (id) => rootGetters["engine/getEngineName"](id) === "engine.wordpress",
    isMagento: (state, getters, rootState, rootGetters) => (id) => rootGetters["engine/getEngineName"](id) === "engine.magento",

    getEngine: state => (id) => state.engines.find(item => item.id === id),
    getEngines: state => state.engines,
    getEnginesIds: state => state.enginesIds
}

export const mutations = {
    SET_ENGINES(state, engines) {
        state.engines = engines;

        const ids = [];
        for (let engine of engines) {
            ids.push(engine.id);
        }

        state.enginesIds = ids
    }
}

export const actions = {
    setEngines({commit}, payload) {
        commit('SET_ENGINES', payload)
    }
}
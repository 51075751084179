export const paginationHelper = {
    getElementsCount,
    getElementsAmount,
    onFiltered,
    setCurrentPage,
    expandAll,
    collapseAll,
    setToFirstPageAndRefresh,
    setToFirstPageAndCallback
};

function getElementsCount(table) {
    if (table.perPage > table.totalRows) {
        return table.totalRows
    }

    if (table.currentPage > 1) {
        const calculated = table.currentPage * table.perPage;
        if (calculated > table.totalRows) {
            return table.totalRows;
        }

        return calculated;
    }

    return table.perPage;
}

function getElementsAmount(table) {
    if (table.totalRows === 0) {
        return 0;
    }

    if (table.currentPage > 1) {
        return table.currentPage * table.perPage - table.perPage;
    }

    return table.currentPage;
}

/**
 * Filter the data of search
 */
function onFiltered(table, filteredItems) {
    // Trigger pagination to update the number of buttons/pages due to filtering
    table.totalRows = filteredItems.length;
    table.currentPage = 1;
}

function expandAll(vue, elements) {
    for(const item of elements) {
        vue.$set(item, '_showDetails', true)
    }
}

function collapseAll(vue, elements) {
    for(const item of elements) {
        vue.$set(item, '_showDetails', false)
    }
}

function setCurrentPage(table, inputPage) {
    try {
        let page = Number(inputPage)
        if (page <= 0) {
            page = 1
        }

        let pages = 0
        for (let index = table.rows; index > 0; index -= table.perPage) {
            ++pages
        }

        if (page > pages) {
            page = pages
        }

        table.currentPage = page
    } catch (error) {
        console.log(error)
    }
}

function setToFirstPageAndRefresh(vue, table, refName, currentPage = 1) {
    setTimeout(() => {
        table.currentPage = currentPage
        vue.$refs[refName].refresh()
    }, 250)
}

function setToFirstPageAndCallback(table, callback) {
    setTimeout(() => {
        table.currentPage = 1
        callback()
    }, 250)
}
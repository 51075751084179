export const state = () => ({
    path: "/dashboard/home",
})

export const getters = {
    getPath: state => state.path
}

export const mutations = {
    SET_PATH(state, payload) {
        state.path = payload
    },

    CLEAR_PATH(state) {
        state.path = "/dashboard/home"
    }
}

export const actions = {
    setPath({commit}, payload) {
        commit("SET_PATH", payload)
    },

    clearPath({commit}, payload) {
        commit("CLEAR_PATH", payload)
    }
}
export const state = () => ({
    storeLimits: [],
    currentMultiStore: null,
    currentMultiStoreSubscription: null
})

export const getters = {
    getStoreLimits: state => state.storeLimits,
    getCurrentMultiStore: state => state.currentMultiStore,
    getCurrentMultiStoreSubscription: state => state.currentMultiStoreSubscription
}

export const mutations = {
    SET_STORE_LIMITS(state, storeLimits) {
        state.storeLimits = storeLimits;
    },

    SET_CURRENT_MULTISTORE(state, currentMultiStore) {
        state.currentMultiStore = currentMultiStore
    },

    SET_CURRENT_MULTISTORE_SUBSCRIPTION(state, currentMultiStoreSubscription) {
        state.currentMultiStoreSubscription = currentMultiStoreSubscription
    }
}

export const actions = {
    setStoreLimits({commit}, payload) {
        commit("SET_STORE_LIMITS", payload)
    },

    setCurrentMultiStore({commit}, payload) {
        commit("SET_CURRENT_MULTISTORE", payload)
    },

    setCurrentMultiStoreSubscription({commit}, payload) {
        commit("SET_CURRENT_MULTISTORE_SUBSCRIPTION", payload)
    }
}
export const state = () => ({
    marketId: '',
    markets: [],
    marketsIds: []
})

export const getters = {
    selectedMarket: state => {
        return state.markets.find(item => item.id === state.marketId);
    },

    currentCurrency: state => {
        const market = state.markets.find(item => item.id === state.marketId);
        if (!market) {
            return ""
        }

        return market.currency
    },

    getMarkets: state => state.markets,
    getMarketsIds: state => state.marketsIds,
    getMarketId: state => state.marketId,
    getMarket: state => (id) => state.markets.find(item => item.id === id),

    getMarketName: state => (id) => {
        const market = state.markets.find(item => item.id === id)
        if (!market) {
            return ""
        }

        return market.i18nTranslation
    },

    getMarketCurrency: state => (id) => {
        const market = state.markets.find(item => item.id === id)
        if (!market) {
            return ""
        }

        return market.currency
    }
}

export const mutations = {
    SET_SELECTED_MARKET_ID(state, marketId) {
        state.marketId = marketId;
    },

    SET_MARKETS(state, markets) {
        state.markets = markets

        const ids = [];
        for (let market of markets) {
            ids.push(market.id);
        }

        state.marketsIds = ids
    }
}

export const actions = {
    setSelectedMarketId({commit}, payload) {
        commit('SET_SELECTED_MARKET_ID', payload)
    },

    setMarkets({commit}, payload) {
        commit('SET_MARKETS', payload)
    }
}
export const state = () => ({
    onlyBeta: false,
})

export const getters = {
    getOnlyBeta: state => state.onlyBeta
}

export const mutations = {
    SET_ONLY_BETA(state, payload) {
        state.onlyBeta = payload
    }
}

export const actions = {
    setOnlyBeta({commit}, payload) {
        commit("SET_ONLY_BETA", payload)
    }
}
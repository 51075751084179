import axios from "axios";
import store from "@/state/store";

export default [
    {
        path: "/",
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login')
    },
    {
        'path': '/p/:id',
        name: 'affiliate-redirect',
        component: () => import('../views/pages/affiliate_program/affiliate-redirect')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register')
    },
    {
        path: '/register/:id',
        name: 'register id',
        component: () => import('../views/pages/account/register')
    },
    // {
    //     path: '/view/:id',
    //     name: 'view id',
    //     component: () => import('../views/pages/account/custom-path-view')
    // },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('../views/pages/account/verify')
    },
    {
        path: '/verify-new-session',
        name: 'verify new session',
        component: () => import('../views/pages/account/verify-new-session')
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../views/pages/account/forgot-password')
    },
    {
        path: "/change-password",
        name: "change-password",
        component: () => import('@/views/pages/account/change-password')
    },
    {
        path: '/dashboard/logout',
        redirect: '/login'
    },
    {
        path: '/dashboard/marketplace',
        name: 'marketplace',
        component: () => import('../views/pages/marketplace/marketplace')
    },
    {
        path: '/dashboard/marketplace/payment_status',
        name: 'marketplace payment status',
        component: () => import('../views/pages/marketplace/marketplace-payment-status')
    },
    {
        path: '/dashboard/marketplace/payment/:id',
        name: 'marketplace payment with id',
        component: () => import('../views/pages/marketplace/marketplace-payment')
    },
    {
        path: '/dashboard/marketplace/user/orders',
        name: 'marketplace user orders',
        component: () => import('../views/pages/marketplace/user/orders')
    },
    {
        path: '/dashboard/marketplace/user/subscriptions',
        name: 'marketplace user subscriptions',
        component: () => import('../views/pages/marketplace/user/subscriptions')
    },
    {
        path: '/dashboard/news',
        name: 'news',
        component: () => import('../views/pages/dashboard/news')
    },
    {
        path: '/dashboard/instructions-categories',
        name: 'instructions-categories',
        component: () => import('../views/pages/dashboard/instructions-categories')
    },
    {
        path: '/dashboard/instructions/:id',
        name: 'instructions',
        component: () => import('../views/pages/dashboard/instructions')
    },
    {
        path: '/dashboard/e-wallet',
        name: 'e-wallet',
        component: () => import('../views/pages/dashboard/e-wallet')
    },
    {
        path: '/dashboard/home',
        name: 'home',
        component: () => import('../views/pages/dashboard/home')
    },
    {
        path: '/dashboard/user/profile',
        name: 'profile',
        component: () => import('../views/pages/user/profile')
    },
    {
        path: '/dashboard/user/allegro',
        name: 'allegro',
        component: () => import('../views/pages/user/allegro'),
        meta: {
            requiredPermissions: [ "ALLEGRO" ]
        }
    },
    {
        path: '/dashboard/user/allegro/status',
        name: 'allegro status',
        component: () => import('../views/pages/user/allegro/allegro-status')
    },
    {
        path: '/dashboard/user/allegro/connect',
        name: 'allegro connect',
        component: () => import('../views/pages/user/allegro/connect'),
        meta: {
            requiredPermissions: [ "ALLEGRO" ]
        }
    },
    {
        path: '/dashboard/user/allegro/after-sale-service/:allegroAccountId',
        name: 'allegro after sale service',
        component: () => import('../views/pages/user/allegro/after-sale-service'),
        meta: {
            requiredPermissions: [ "ALLEGRO" ]
        }
    },
    {
        path: '/dashboard/user/allegro/auctions/:allegroAccountId',
        name: 'allegro-auctions',
        component: () => import('../views/pages/user/allegro/auctions'),
        meta: {
            requiredPermissions: [ "ALLEGRO" ]
        }
    },
    {
        path: '/dashboard/user/business',
        name: 'business',
        component: () => import('../views/pages/user/business')
    },
    {
        path: '/dashboard/user/shops',
        name: 'user-shops',
        component: () => import('../views/pages/shop/user-shops')
    },
    {
        path: '/dashboard/user/shop/:id/details',
        name: 'user shop details',
        component: () => import('@/views/pages/shop/shop-details')
    },
    {
        path: '/dashboard/user/orders',
        name: 'orders',
        component: () => import('../views/pages/shop/orders')
    },
    {
        path: '/dashboard/shop/categories',
        name: 'shop-categories',
        component: () => import('@/views/pages/shop/shop-categories')
    },
    {
        path: '/dashboard/user/shop/products',
        name: 'user-shop-products',
        component: () => import('@/views/pages/shop/user-shop-products')
    },
    {
        path: '/dashboard/products-catalog',
        name: 'products catalog',
        component: () => import('@/views/pages/shop/products-catalog')
    },
    {
        path: '/dashboard/wordpress-not-activated',
        name: 'wordpress not activated',
        component: () => import('@/views/pages/shop/error/wordpress-not-activated')
    },
    {
        path: '/dashboard/products',
        name: 'products',
        component: () => import('../views/pages/shop/products')
    },
    {
        path: "/dashboard/ai-products-generate",
        name: "ai products generate",
        component: () => import('../views/pages/shop/ai-products-generate')
    },
    {
        path: '/dashboard/user/security',
        name: 'security',
        component: () => import('../views/pages/user/security')
    },
    {
        path: '/dashboard/admin/aderlo-cashback-panel',
        name: 'aderlo cashback panel',
        component: () => import('../views/pages/admin/aderlo-cashback-panel')
    },
    {
        path: '/dashboard/admin/manage-roles',
        name: 'admin manage roles',
        component: () => import('../views/pages/admin/manage-roles'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/markets',
        name: 'admin markets',
        component: () => import('../views/pages/admin/markets'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: "/dashboard/admin/aderlo/products",
        name: "admin aderlo products",
        component: () => import('../views/pages/admin/aderlo/products'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: "/dashboard/admin/aderlo/categories",
        name: "admin aderlo categories",
        component: () => import('../views/pages/admin/aderlo/shop-categories.vue'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: "/dashboard/admin/aderlo/shop/products",
        name: "admin aderlo shop products",
        component: () => import('../views/pages/admin/aderlo/shop-products'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: "/dashboard/admin/global-shipping-rates",
        name: "admin global shipping rates",
        component: () => import('../views/pages/admin/global-shipping-rates'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/allegro-history-auctions',
        name: 'admin allegro history auctions',
        component: () => import('../views/pages/admin/allegro/allegro-history-auctions'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/allegro/statistics',
        name: 'admin allegro statistics',
        component: () => import('../views/pages/admin/allegro/allegro-statistics'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/allegro-accounts',
        name: 'admin allegro accounts',
        component: () => import('../views/pages/admin/allegro/allegro-accounts'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/email-templates',
        name: 'email-templates',
        component: () => import('../views/pages/admin/email-templates'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parser/parsers',
        name: 'parsers',
        component: () => import('../views/pages/admin/parser/parsers'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parser/create-or-edit',
        name: 'admin parser create or edit',
        component: () => import('../views/pages/admin/parser/create-or-edit-parser'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parser/create-or-edit/:warehouseId',
        name: 'admin parser create or edit with warehouse id',
        component: () => import('../views/pages/admin/parser/create-or-edit-parser'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parser/notifications',
        name: 'admin parser notifications',
        component: () => import('../views/pages/admin/parser/notifications-for-new-tags'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/languages',
        name: 'admin languages',
        component: () => import('../views/pages/admin/language/languages'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/translations',
        name: 'admin translations',
        component: () => import('../views/pages/admin/language/translations'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/help-videos/:id',
        name: 'help videos',
        component: () => import('../views/pages/admin/help-videos'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/custom-paths/:id',
        name: 'custom paths',
        component: () => import('../views/pages/admin/custom-path/custom-paths'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/custom-paths/create-or-edit/:languageId',
        name: 'custom paths create or edit',
        component: () => import('../views/pages/admin/custom-path/create-or-edit'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/carriers',
        name: 'admin carriers',
        component: () => import('../views/pages/admin/carriers'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/information-bars/:languageId',
        name: 'admin information-bars',
        component: () => import('../views/pages/admin/information-bars'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parameters',
        name: 'admin parameters',
        component: () => import('../views/pages/admin/parameters'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/investors/list',
        name: 'investors-list',
        component: () => import('../views/pages/admin/investors/list'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/parser/banned-phrase',
        name: 'parser-banned-phrase',
        component: () => import('../views/pages/admin/parser/banned-phrases'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/categories',
        name: 'categories',
        component: () => import('../views/pages/admin/categories'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/news',
        name: 'admin-news',
        component: () => import('../views/pages/admin/news'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/instructions/:languageId',
        name: 'admin-instructions',
        component: () => import('../views/pages/admin/instructions'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/instructions-categories/:languageId',
        name: 'admin-instructions-category',
        component: () => import('../views/pages/admin/instructions-categories.vue'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/shortcodes',
        name: 'shortcodes',
        component: () => import('../views/pages/admin/shortcodes'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/wholesaler/registration-forms',
        name: 'admin-wholesaler-registration-forms',
        component: () => import('../views/pages/admin/wholesaler/registration-forms'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/shops',
        name: 'shops',
        component: () => import('../views/pages/admin/shops'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/users',
        name: 'users',
        component: () => import('../views/pages/admin/users'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/products',
        name: 'admin-products',
        component: () => import('../views/pages/admin/products'),
        meta: {
            requiredPermissions: [ "CORE_ADMIN_PRODUCTS" ]
        }
    },
    {
        path: '/dashboard/admin/depot/products/:id',
        name: 'depot products',
        component: () => import('../views/pages/core/depot/products'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: "/dashboard/admin/depot/sections/:id",
        name: 'depot sections',
        component: () => import('../views/pages/core/depot/sections'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/product/show/:id',
        name: 'admin product show',
        component: () => import('../views/pages/admin/product/show-product'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/warehouse/show/:id',
        name: 'admin warehouse show',
        component: () => import('../views/pages/admin/wholesaler/show-warehouse'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/registration-access',
        name: 'admin registration access',
        component: () => import('../views/pages/admin/registration-access'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/logs',
        name: 'logs',
        component: () => import('../views/pages/admin/logs'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/pickup-points',
        name: 'pickup-points',
        component: () => import('../views/pages/admin/pickup-points'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/warehouse-status',
        name: 'warehouse-status',
        component: () => import('../views/pages/admin/wholesaler/warehouse-status'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/warehouses',
        name: 'warehouses',
        component: () => import('../views/pages/admin/wholesaler/warehouses'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/industries',
        name: 'industries',
        component: () => import('../views/pages/admin/industries'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/admin/marketplace/products',
        name: 'admin marketplace products',
        component: () => import('@/views/pages/admin/marketplace/products'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/marketplace/product/:id/details',
        name: 'admin marketplace product details',
        component: () => import('@/views/pages/admin/marketplace/product-details'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/marketplace/orders',
        name: 'admin marketplace orders',
        component: () => import('@/views/pages/admin/marketplace/orders'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/admin/allegrovpn',
        name: 'admin allegro vpn',
        component: () => import('../views/pages/admin/allegro-vpn'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/wholesaler/management',
        name: 'wholesaler-management',
        component: () => import('../views/pages/warehouse/warehouse-management')
    },
    {
        path: '/dashboard/wholesaler/available',
        name: 'wholesaler-available',
        component: () => import('../views/pages/warehouse/available-wholesalers')
    },
    {
        path: '/dashboard/aderlo-cashback',
        name: 'aderlo-cashback',
        component: () => import('../views/pages/dashboard/aderlo-cashback')
    },
    {
        path: '/dashboard/ecommerce-points-pool',
        name: 'ecommerce-points-pool',
        component: () => import('../views/pages/dashboard/ecommerce-points-pool')
    },  {
        path: '/dashboard/ecat-rewards-program',
        name: 'ecat-rewards-program',
        component: () => import('../views/pages/dashboard/ecat-rewards-program')
    },
    {
        path: '/dashboard/partner-program/account-list',
        name: 'account-list',
        component: () => import('../views/pages/affiliate_program/account-list')
    },
    {
        path: '/dashboard/partner-program/wholesalers-list',
        name: 'wholesalers-list',
        component: () => import('../views/pages/affiliate_program/wholesalers-list')
    },
    {
        path: '/dashboard/core/withdrawals',
        name: 'core withdrawals',
        component: () => import('../views/pages/core/withdrawals'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/customers',
        name: 'core customers',
        component: () => import('../views/pages/core/customers/customers.vue'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/customers/orders/:customerId',
        name: 'core customers orders',
        component: () => import('../views/pages/core/customers/orders.vue'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/products/variations',
        name: 'core products variations',
        component: () => import('../views/pages/core/products/variations'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/shop/show/:id',
        name: 'core shop show',
        component: () => import('../views/pages/core/show-shop'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/monthly-order-reports',
        name: 'core monthly order reports',
        component: () => import('../views/pages/core/monthly-order-reports'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/package-senders',
        name: 'core package senders',
        component: () => import('../views/pages/core/package-senders'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/shipping/inpost',
        name: 'core shipping post',
        component: () => import('../views/pages/core/order-shipping/shipping-inpost'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/shipping/dpd',
        name: 'core shipping dpd',
        component: () => import('../views/pages/core/order-shipping/shipping-dpd'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/add-product',
        name: 'core add product',
        component: () => import('../views/pages/core/add-product'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/orders',
        name: 'core orders',
        component: () => import('../views/pages/core/orders'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/order/details',
        name: 'core-order-details',
        component: () => import('../views/pages/core/order-details'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/order-add-carrier',
        name: 'core order add carrier',
        component: () => import('../views/pages/core/order-add-carrier'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/dashboard/core/depots',
        name: 'core depots',
        component: () => import('../views/pages/core/depots'),
        meta: {
            requiredPermissions: [ "TECHNICAL_DEPARTMENT" ]
        }
    },
    {
        path: '/error-404',
        name: 'Error-404',
        component: () => import('../views/pages/utility/error-404')
    },
    {
        path: '/error-500',
        name: 'Error-500',
        component: () => import('../views/pages/utility/error-500')
    },
    {
        path: '/maintenance',
        name: 'Maintenance',
        component: () => import('../views/pages/utility/maintenance')
    },
    {
        path: '/beta-access',
        name: 'Beta Access',
        component: () => import('../views/pages/utility/beta-access')
    },
    {
        path: '/dashboard/tpay/:status',
        name: 'tpay status',
        component: () => import('../views/pages/payment/tpay')
    },
    {
        path: '/:customPath(.*)',
        name: 'custom-path',
        component: () => import('../views/pages/account/custom-path-view'),
        beforeEnter: async (to, from, next) => {
            try {
                const { data } = await axios.get(`/custom-path`, {
                    data: {},

                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    },

                    params: {
                        languageId: store.getters["translation/getCurrentLocale"],
                        path: "/" + to.params.customPath
                    }
                });

                await store.dispatch("custompath/setCustomPath", data);
                next();
            } catch (error) {
                console.log(error);

                if (error.response && error.response.status === 404) {
                    next({ name: 'error-404' });
                } else {
                    next();
                }
            }
        }
    },

    {
        path: "/:catchAll(.*)",
        name: 'error-404',
        redirect: '/error-404',
    }
]
export const state = () => ({
    formSelects: []
})

export const getters = {
    getFormSelect: state => (formSelectId) => {
        if (!state.formSelects || !formSelectId) {
            return null
        }

        return state.formSelects.find(item => item.id === formSelectId) || null
    }
}

export const mutations = {
    SET_FORM_SELECT(state, payload) {
        const { id, value } = payload;
        const existingItem = state.formSelects.find(item => item.id === id);

        if (existingItem) {
            existingItem.value = value;
        } else {
            state.formSelects.push({ id, value });
        }
    }
}

export const actions = {
    setFormSelect({ commit }, payload) {
        commit('SET_FORM_SELECT', payload)
    }
}
import {errorCatcher} from "@/helpers/error-catcher";

export const userService = {
    login,
    loginTwo,
    logout,
    redirect
};

import {useUserStore} from "@/store/user";
import axios from "axios";
import router from '@/router'
import store from '@/state/store';
import app from "@/main";

// import { BToast } from 'bootstrap-vue'

function redirect() {
    router.push("/").catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
            console.log(err)
        }
    });
}

function push(path, query) {
    router.push({ path: path, query: query }).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
            console.log(err)
        }
    });
}

async function login(redirect) {
    await loginTwo(redirect, false)
}

async function loginTwo(redirect, canRedirect) {
    const userStore = useUserStore()

    try {
        const { data } = await axios.get(`/user`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: {},
        })

        await userStore.modifyUser(data)
        if (data.needsToSetFullName) {
            app.$root.$emit("needs-to-set-full-name-modal")
            return
        }

        await loadRolePermissions()
        if (redirect) {
            await router.push("/dashboard/home")
            return;
        }

    } catch (error) {
        errorCatcher.catchErrors(error)

        if (error.response) {
            let json = JSON.parse(JSON.stringify(error.response.data))
            let jsonError = json.error;

            const urlSearchParams = new URLSearchParams(window.location.search);
            const urlParams = Object.fromEntries(urlSearchParams.entries());

            if (!urlParams.code) {
                if (jsonError.code === 2 || jsonError.code === 3) { // not verified phone number & not verified email number
                    await push("/verify")
                    return;
                }
            }

            if (urlParams.code) {
                return;
            }
        }

        userStore.modifyUser(null)

        if (canRedirect) {
            await this.redirect()
        }

        return;
    }

    let user = userStore.getUser;
    if (!user) {
        userStore.modifyUser(null)

        if (canRedirect) {
            await this.redirect()
        }
    } else {
        const host = window.location.hostname;
        const isBeta = host === "localhost" || host === "beta.ec-at.com"
        await store.dispatch("beta/setOnlyBeta", isBeta)

        if (!user.allowedOnBetaEnvironment && isBeta) {
            await router.push("/beta-access").catch(err => {
                if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                    console.log(err)
                }
            });
        }
    }
}

function logout() {
    axios.delete(`/user/session/delete-current`, {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
        },
        data: {}
    }).then(() => {
        useUserStore().modifyUser(null)
        push('/')
    }).catch((error) => {
        errorCatcher.catchErrors(error)
    })
}

async function loadRolePermissions() {
    try {
        const result = await axios.get(`/role/permission/me`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            data: {},
        })

        await store.dispatch('role/setRolePermissions', result.data)
    } catch (error) {
        errorCatcher.catchErrors(error)
    }
}
<template>
  <b-modal
      centered
      id="modal-1"
      v-model="visibleModal"
      title="Autoryzacja 2FA"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <label>Kod weryfikacyjny:</label>
      <input v-model="form.otpCode" class="form-control" :class="{ 'is-invalid': submitted && $v.form.otpCode.$error }" />
      <div v-if="!$v.form.otpCode.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="text-right">
      <b-button @click="authorize" variant="success">Zweryfikuj</b-button>
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";
import {userService} from "@/helpers/user.service";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import {required} from "vuelidate/lib/validators";

export default {
  name: "authorize-2fa-modal",

  data() {
    return {
      submitted: false,
      visibleModal: false,

      form: {
        otpCode: ""
      }
    }
  },

  validations: {
    form: {
      otpCode: { required }
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.otpCode = ""
    },

    authorize() {
      this.submitted = true

      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.form)
      axios.post(`/user/2fa/authorize`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        Swal.fire("Sukces!", "Weryfikacja dwuetapowa powiodła się!", "success").then(() => {
          this.hideModal()
          userService.login(true)
        })
      }).catch((error) => {
        errorCatcher.catchErrors(error);
      })

      this.submitted = false
    }
  }

}
</script>
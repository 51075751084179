<template>
  <ul>
    <template v-for="(child, index) in children">
      <li :key="index">
        <details @click="ecatClickCategory(child)">
          <summary style="z-index: 1;"> {{ child.name }} {{ showProductCount ? '(' + $t('categories.product-count', { "count": child.productCount, "profitMargin": child.profitMargin}) + ')' : "" }} <a style="z-index: 999;" @click="ecatClickEdit(child)" href="#" class="text-primary"><i class="mdi mdi-pencil font-size-18"></i></a> <a style="z-index: 999;" @click="ecatClickDelete(child)" href="#" class="text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a></summary>
          <category-parent v-if="child.categories" :show-product-count="showProductCount" :trees="child.categories"></category-parent>
        </details>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      fn: null
    }
  },

  props: {
    children: [],
    showProductCount: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ecatClickCategory(child) {
      this.$root.$emit('ecat-click-category', child)
    },

    ecatClickEdit(child) {
      this.$root.$emit('ecat-click-edit', child)
    },

    ecatClickDelete(child) {
      this.$root.$emit('ecat-click-delete', child)
    }
  },

  mounted() {
    this.fn = () => {
      this.$forceUpdate()
    }

    this.$root.$on('refresh-tree', this.fn)
  },

  beforeDestroy() {
    this.$root.$off('refresh-tree', this.fn)
  }

}
</script>
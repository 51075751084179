export const state = () => ({
    scriptLoaded: false
})

export const getters = {
    isScriptLoaded: state => state.scriptLoaded
}

export const mutations = {
    SET_SCRIPT_LOADED(state) {
        state.scriptLoaded = true
    }
}

export const actions = {
    setScriptLoaded({commit}) {
        commit('SET_SCRIPT_LOADED')
    }
}
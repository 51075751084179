export const state = () => ({
    themeDark: true
})

export const getters = {
    isThemeDark: state => state.themeDark
}

export const mutations = {
    SWITCH_THEME(state) {
        state.themeDark = !state.themeDark;
    },

    UPDATE_THEME(state) {
        if (state.themeDark) {
            document.body.setAttribute("theme-mode", "dark")
            return;
        }

        document.body.setAttribute("theme-mode", "light")
    }
}

export const actions = {
    switchTheme({commit}, payload) {
        commit('SWITCH_THEME', payload)
    },

    updateTheme({commit}, payload) {
        commit('UPDATE_THEME', payload)
    }
}
<template>
  <div>
    <div v-if="helpVideo" class="help-video" @click="visibleModal = true">
      <i class="fas fa-play-circle" />
    </div>

    <b-modal
        id="modal-1"
        size="xl"
        v-model="visibleModal"
        v-if="helpVideo"
        :title="$t('help-video.modal-name')"
        title-class="font-18"
        hide-footer
        @hide="visibleModal = false"
        @esc="visibleModal = false">
      <form>
        <iframe width="100%" height="550" :src="getVideoUrl()" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen="" title="ECAT eCommerce" />
      </form>
    </b-modal>

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "help-video-balloon",

  data() {
    return {
      helpVideo: null,
      visibleModal: false
    }
  },

  methods: {
    async loadHelpVideo(path) {
      this.helpVideo = null
      this.visibleModal = false

      if (path === "/") {
        return
      }

      try {
        const { data } = await axios.get(`/help-video`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          params: {
            languageId: this.$store.getters["translation/getCurrentLocale"],
            path: decodeURIComponent(path)
          }
        })

        this.helpVideo = data
      } catch (error) {
        // error ignored
      }
    },

    getVideoUrl() {
      if (!this.helpVideo) {
        return ""
      }

      switch(this.helpVideo.videoType) {
        case "VIMEO":
          return `https://player.vimeo.com/video/${this.helpVideo.videoId}`
        case "YOUTUBE":
          return `https://www.youtube.com/embed/${this.helpVideo.videoId}`
      }

      return ""
    }

  },

  mounted() {
    this.$root.$on("change-language", async () => await this.loadHelpVideo(this.$route.path))
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  },

  watch: {
    $route: {
      immediate: true,
      async handler(to) {
        await this.loadHelpVideo(to.path)
      }
    }
  }

}
</script>

<style scoped>
.help-video {
  position: fixed;
  bottom: 30px;
  right: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #212732;
  box-shadow: 0 0 15px rgba(0, 255, 60, 0.7);
  animation: glow 1.5s ease-in-out infinite alternate;
  z-index: 3;
  display: flex;
  align-items: center;
  cursor: pointer;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 15px rgba(0, 255, 60, 0.7);
  }
  100% {
    box-shadow: 0 0 30px rgba(0, 255, 60, 0.7);
  }
}

.help-video i {
  color: #00ff3c;
  font-size: 24px;
}
</style>
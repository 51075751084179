export const state = () => ({
    currentAllegroAccountId: "",
    selectedMarketplaces: []
})

export const getters = {
    getCurrentAllegroAccountId: state => state.currentAllegroAccountId,
    getSelectedMarketplaces: state => state.selectedMarketplaces,
}

export const mutations = {
    SET_CURRENT_ALLEGRO_ACCOUNT_ID(state, currentAllegroAccountId) {
        state.currentAllegroAccountId = currentAllegroAccountId
    },

    ADD_OR_REMOVE_MARKETPLACE(state, marketplace) {
        if (state.selectedMarketplaces.includes(marketplace)) {
            state.selectedMarketplaces = state.selectedMarketplaces.filter(element => element !== marketplace)
        } else {
            state.selectedMarketplaces.push(marketplace)
        }
    }
}

export const actions = {
    setCurrentAllegroAccountId({commit}, payload) {
        commit("SET_CURRENT_ALLEGRO_ACCOUNT_ID", payload)
    },

    addOrRemoveMarketplace({commit}, payload) {
        commit("ADD_OR_REMOVE_MARKETPLACE", payload)
    }
}